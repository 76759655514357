import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import PageHeader from '~/components/PageHeader';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader">
    </PageHeader>
    <p><inlineCode parentName="p">{`Pixel`}</inlineCode>{` er bedre egnet for designelementer som skal være faste i størrelse, som logoer eller ikoner, mens `}<inlineCode parentName="p">{`rem`}</inlineCode>{` kan være mer egnet for typografiske elementer og layoutelementer som skal skalere basert på skjermstørrelse.`}</p>
    <p>{`I designsystemet er alle relevante tokens i CSS, SASS og LESS i `}<inlineCode parentName="p">{`rem`}</inlineCode>{`-enheten.
Men ønsker du valgmuligheten finnes våre tokens i Javascript i både `}<inlineCode parentName="p">{`px`}</inlineCode>{`- og `}<inlineCode parentName="p">{`rem`}</inlineCode>{`-enheter. De kan hentes ut slik:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { fontSizes } from '@entur/tokens';

fontSizes.small; // gir tallet 12 (i px)
fontSizes.rem.small; // gir tallet 0.75 (i rem)
`}</code></pre>
    <p>{`En `}<inlineCode parentName="p">{`.rem`}</inlineCode>{` etter token-navnet vil altså la deg velge mellom `}<inlineCode parentName="p">{`rem`}</inlineCode>{`-størrelser i stedet for `}<inlineCode parentName="p">{`px`}</inlineCode>{`-størrelser.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      